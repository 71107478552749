import React from 'react';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import { HydraAdmin, hydraClient, fetchHydra as baseFetchHydra } from '@api-platform/admin';
import {ReferenceField, ReferenceInput, SelectInput, TextField, ImageField, ImageInput, FunctionField } from 'react-admin';
import { Editor } from 'react-draft-wysiwyg';
import authProvider from './authProvider';
import { Redirect } from 'react-router-dom';
import './App.css';
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const entrypoint = '//hlp-api.yesidoit.com/api';
const apiPrefix = '/api';
const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};

const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});

const customFetch = (url, options = {}) => {
    // fix https://github.com/api-platform/api-platform/issues/584
    url = url.replace(`${apiPrefix}${apiPrefix}/`, `${apiPrefix}/`);
    return fetchHydra(url, options);
};

const dataProvider = api => hydraClient(api, customFetch);

const EditorComponent = () => <Editor />;

const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => {
            const articles = api.resources.find(({name}) => 'articles' === name);
            const articlesCategories = articles.fields.find(({name}) => 'category' === name);
            const articlesAuthors = articles.fields.find(({name}) => 'author' === name);
            const articlesImages = articles.fields.find(({name}) => 'image' === name);

            const contentField = articles.fields.find(f => 'content' === f.name);
            contentField.input = props => (
                <EditorComponent {...props} source="content" />
            );
            contentField.input.defaultProps = {
                addField: true,
                addLabel: true
            };

            // add "allowEmpty" attr if needed

            // Set the field in the list and the show views
            articlesCategories.field = props => (
                <ReferenceField source={articlesCategories.name} reference={articlesCategories.reference.name}
                                key={articlesCategories.name} {...props}>
                    <TextField source="title"/>
                </ReferenceField>
            );
            // Set the input in the edit and create views
            articlesCategories.input = props => (
                <ReferenceInput source={articlesCategories.name} reference={articlesCategories.reference.name}
                                label="Category" key={articlesCategories.name} {...props} >
                    <SelectInput optionText="title"/>
                </ReferenceInput>
            );
            articlesAuthors.field = props => (
                <ReferenceField source={articlesAuthors.name} reference={articlesAuthors.reference.name}
                                key={articlesAuthors.name} {...props}>
                    <TextField source="email"/>
                </ReferenceField>
            );
            articlesAuthors.input = props => (
                <ReferenceInput source={articlesAuthors.name} reference={articlesAuthors.reference.name}
                                label="Author" key={articlesAuthors.name} {...props}>
                    <SelectInput optionText="email"/>
                </ReferenceInput>
            );
            articlesImages.field = props => (
                <ReferenceField source={articlesImages.name} reference={articlesImages.reference.name}
                                key={articlesImages.name} {...props}>
                    <ImageField source="contentUrl"/>
                </ReferenceField>
            );
            articlesImages.input = props => (
                <ReferenceInput source={articlesImages.name} reference={articlesImages.reference.name}
                                key={articlesImages.name} {...props} allowEmpty>
                    <SelectInput optionText="title"/>
                </ReferenceInput>
            );

            api.resources.map(resource => {
                if ('http://schema.org/ImageObject' === resource.id) {
                    resource.fields.map(field => {
                        if ('http://schema.org/contentUrl' === field.id) {
                            field.denormalizeData = value => ({
                                src: value
                            });

                            field.field = props => (
                                <FunctionField
                                    key={field.name}
                                    render={
                                        record => (
                                            <ImageField key={field.name} record={record} source={`${field.name}.src`}/>
                                        )
                                    }
                                    source={field.name}
                                    {...props}
                                />
                            );

                            field.input = props => (
                                <ImageInput accept="image/*" key={field.name} multiple={false} source={field.name} {...props}>
                                    <ImageField source="src"/>
                                </ImageInput>
                            );

                            field.normalizeData = value => {
                                if (value && value.rawFile instanceof File) {
                                    const body = new FormData();
                                    body.append('file', value.rawFile);

                                    return fetch(`${entrypoint}/image_objects`, { body, method: 'POST', headers: new Headers(fetchHeaders) })
                                        .then(response => response.json())
                                        .then(data => data.contentUrl);
                                }

                                return value.src;
                            };
                        }

                        return field;
                    });
                }

                return resource;
            });

            return {api};
        },
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [{
                            props: {
                                path: '/',
                                render: () => <Redirect to={`/login`}/>,
                            },
                        }],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );

export default props => (
    <HydraAdmin
        apiDocumentationParser={apiDocumentationParser}
        authProvider={authProvider}
        entrypoint={entrypoint}
        dataProvider={dataProvider}
    />
);

